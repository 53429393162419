import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  fragment SecondaryMenuSubItem on MenuItem {\n    id\n    name\n    category {\n      id\n      name\n    }\n    url\n    collection {\n      id\n      name\n    }\n    page {\n      slug\n    }\n  }\n\n  query SecondaryMenu {\n    menu(id: \"TWVudTo4\") {\n      items {\n        ...SecondaryMenuSubItem\n        children {\n          ...SecondaryMenuSubItem\n        }\n      }\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  fragment SecondaryMenuSubItem on MenuItem {\n    id\n    name\n    category {\n      id\n      name\n    }\n    url\n    collection {\n      id\n      name\n    }\n    page {\n      slug\n    }\n  }\n\n  query SecondaryMenu {\n    menu(id: \"TWVudTo1\") {\n      items {\n        ...SecondaryMenuSubItem\n        children {\n          ...SecondaryMenuSubItem\n        }\n      }\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  fragment SecondaryMenuSubItem on MenuItem {\n    id\n    name\n    category {\n      id\n      name\n    }\n    url\n    collection {\n      id\n      name\n    }\n    page {\n      slug\n    }\n  }\n\n  query SecondaryMenu {\n    menu(id: \"TWVudToz\") {\n      items {\n        ...SecondaryMenuSubItem\n        children {\n          ...SecondaryMenuSubItem\n        }\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  fragment SecondaryMenuSubItem on MenuItem {\n    id\n    name\n    category {\n      id\n      name\n    }\n    url\n    collection {\n      id\n      name\n    }\n    page {\n      slug\n    }\n  }\n\n  query SecondaryMenu {\n    menu(id: \"TWVudToy\") {\n      items {\n        ...SecondaryMenuSubItem\n        children {\n          ...SecondaryMenuSubItem\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
var secondaryMenu = gql(_templateObject());
var secondaryMenuEN = gql(_templateObject2());
var secondaryMenuPartner = gql(_templateObject3());
var secondaryMenuPartnerEN = gql(_templateObject4());
export var TypedSecondaryMenuQuery = TypedQuery(secondaryMenu);
export var TypedSecondaryMenuQueryEN = TypedQuery(secondaryMenuEN);
export var TypedSecondaryMenuQueryPartner = TypedQuery(secondaryMenuPartner);
export var TypedSecondaryMenuQueryPartnerEN = TypedQuery(secondaryMenuPartnerEN);