import * as React from "react";
import { CachedImage } from "@components/molecules";
import { Carousel } from "../../components";
import noPhotoImg from "../../images/no-photo.svg";

var GalleryCarousel = function GalleryCarousel(_ref) {
  var images = _ref.images;
  return React.createElement("div", {
    className: "product-page__product__gallery"
  }, React.createElement(Carousel, {
    renderCenterLeftControls: function renderCenterLeftControls() {
      return null;
    },
    renderCenterRightControls: function renderCenterRightControls() {
      return null;
    },
    renderBottomCenterControls: function renderBottomCenterControls(props) {
      var indexes = [];

      for (var i = 0; i < props.slideCount; i++) {
        indexes.push(i);
      }

      return React.createElement("ul", {
        className: "product-page__product__gallery__nav"
      }, indexes.map(function (index) {
        return React.createElement("li", {
          key: index,
          onClick: props.goToSlide.bind(null, index),
          className: props.currentSlide === index ? "active" : ""
        }, React.createElement("span", null));
      }));
    }
  }, images.map(function (image) {
    return React.createElement(CachedImage, {
      noLazyLoading: true,
      url: image.url || noPhotoImg,
      key: image.id
    }, React.createElement("img", {
      src: noPhotoImg,
      alt: image.alt
    }));
  })));
};

export default GalleryCarousel;