import React from "react";
import * as S from "./styles";
export var InputLabel = function InputLabel(_ref) {
  var children = _ref.children,
      active = _ref.active,
      labelBackground = _ref.labelBackground,
      _ref$labelTextarea = _ref.labelTextarea,
      labelTextarea = _ref$labelTextarea === void 0 ? false : _ref$labelTextarea;
  return React.createElement(S.Label, {
    active: active,
    labelBackground: labelBackground,
    labelTextarea: labelTextarea
  }, children);
};