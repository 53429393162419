import React from "react";
import { FormattedMessage } from "react-intl";
import { Chip, DropdownSelect, Icon } from "@components/atoms";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";
export var ProductListHeaderProjects = function ProductListHeaderProjects(_ref) {
  var _ref$numberOfProducts = _ref.numberOfProducts,
      numberOfProducts = _ref$numberOfProducts === void 0 ? 0 : _ref$numberOfProducts,
      openFiltersMenu = _ref.openFiltersMenu,
      clearFilters = _ref.clearFilters,
      activeSortOption = _ref.activeSortOption,
      _ref$activeFilters = _ref.activeFilters,
      activeFilters = _ref$activeFilters === void 0 ? 0 : _ref$activeFilters,
      _ref$activeFiltersAtt = _ref.activeFiltersAttributes,
      activeFiltersAttributes = _ref$activeFiltersAtt === void 0 ? [] : _ref$activeFiltersAtt,
      sortOptions = _ref.sortOptions,
      onChange = _ref.onChange,
      onCloseFilterAttribute = _ref.onCloseFilterAttribute;
  return React.createElement(S.Wrapper, null, React.createElement(S.Bar, null, React.createElement(S.LeftSide, null, React.createElement(S.FiltersButton, {
    onClick: openFiltersMenu,
    "data-test": "filtersButton"
  }, React.createElement(Icon, {
    name: "filter",
    size: 24
  }), React.createElement(S.Filters, null, React.createElement(FormattedMessage, commonMessages.filterHeader), " ", activeFilters > 0 && React.createElement(React.Fragment, null, React.createElement("span", null, "(", activeFilters, ")")))), activeFilters > 0 && React.createElement(S.Clear, {
    onClick: clearFilters,
    "data-test": "clearFiltersButton"
  }, React.createElement(FormattedMessage, commonMessages.clearFilterHeader))), React.createElement(S.RightSide, null, React.createElement(S.Element, {
    "data-test": "productsFoundCounter"
  }, React.createElement(S.Label, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ProductListHeaderProjects.ProductListHeaderProjects.3867522872",
    defaultMessage: "Projects:"
  }), " "), numberOfProducts), React.createElement(S.Element, null, React.createElement(S.Sort, null, React.createElement(DropdownSelect, {
    onChange: onChange,
    options: sortOptions,
    value: sortOptions.find(function (option) {
      return option.value === activeSortOption;
    })
  }))))), React.createElement(S.FiltersChipsWrapper, null, activeFiltersAttributes.map(function (_ref2) {
    var attributeSlug = _ref2.attributeSlug,
        valueName = _ref2.valueName,
        valueSlug = _ref2.valueSlug;
    return React.createElement(Chip, {
      onClose: function onClose() {
        return onCloseFilterAttribute(attributeSlug, valueSlug);
      }
    }, valueName);
  })));
};