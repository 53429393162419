import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { PersonalizationFormModal } from "@components/organisms/PersonalizationFormModal";
import { FacebookShareButton, PinterestShareButton, TwitterShareButton, EmailShareButton, FacebookIcon, PinterestIcon, TwitterIcon, EmailIcon } from "react-share";
import { checkoutMessages, commonMessages } from "@temp/intl";
import { generateCollectionUrl } from "../../../../core/utils";
import QuantityInput from "../../molecules/QuantityInput";
import CustomizeButton from "../../molecules/CustomizeButton";
import ProductVariantPicker from "../ProductVariantPicker";
import * as S from "./styles";
import { getAvailableQuantity, getProductPrice, canAddToCart } from "./stockHelpers";
var LOW_STOCK_QUANTITY = 5;

var AddToCartSection = function AddToCartSection(_ref) {
  var availableForPurchase = _ref.availableForPurchase,
      isAvailableForPurchase = _ref.isAvailableForPurchase,
      items = _ref.items,
      name = _ref.name,
      collections = _ref.collections,
      productPricing = _ref.productPricing,
      productVariants = _ref.productVariants,
      queryAttributes = _ref.queryAttributes,
      onAddToCart = _ref.onAddToCart,
      onAttributeChangeHandler = _ref.onAttributeChangeHandler,
      setVariantId = _ref.setVariantId,
      variantId = _ref.variantId;
  var intl = useIntl();

  var _useState = useState(1),
      _useState2 = _slicedToArray(_useState, 2),
      quantity = _useState2[0],
      setQuantity = _useState2[1];

  var _useState3 = useState(0),
      _useState4 = _slicedToArray(_useState3, 2),
      variantStock = _useState4[0],
      setVariantStock = _useState4[1];

  var _useState5 = useState(null),
      _useState6 = _slicedToArray(_useState5, 2),
      variantPricing = _useState6[0],
      setVariantPricing = _useState6[1];

  var _useState7 = useState(false),
      _useState8 = _slicedToArray(_useState7, 2),
      displayModalPersonalisation = _useState8[0],
      setDisplayModalPersonalisation = _useState8[1];

  var availableQuantity = getAvailableQuantity(items, variantId, variantStock);
  var isOutOfStock = !!variantId && variantStock === 0;
  var noPurchaseAvailable = !isAvailableForPurchase && !availableForPurchase;
  var purchaseAvailableDate = !isAvailableForPurchase && availableForPurchase && Date.parse(availableForPurchase);
  var isNoItemsAvailable = !!variantId && !isOutOfStock && !availableQuantity;
  var isLowStock = !!variantId && !isOutOfStock && !isNoItemsAvailable && availableQuantity < LOW_STOCK_QUANTITY;
  var disableButton = !canAddToCart(items, !!isAvailableForPurchase, variantId, variantStock, quantity);

  var renderErrorMessage = function renderErrorMessage(message, testingContextId) {
    return React.createElement(S.ErrorMessage, {
      "data-test": "stockErrorMessage",
      "data-testId": testingContextId
    }, message);
  };

  var onVariantPickerChange = function onVariantPickerChange(_selectedAttributesValues, selectedVariant) {
    if (!selectedVariant) {
      setVariantId("");
      setVariantPricing(null);
      setVariantStock(0);
      return;
    }

    setVariantId(selectedVariant.id);
    setVariantPricing(selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.pricing);
    setVariantStock(selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.quantityAvailable);
  };

  var collectionId = process.env.COLLECTION_ID !== "false" ? process.env.COLLECTION_ID : null;
  return React.createElement(S.AddToCartSelection, null, React.createElement(S.ProductNameHeader, {
    "data-test": "productName"
  }, name), !collectionId ? React.createElement(Link, {
    to: generateCollectionUrl(collections[0].id, collections[0].name),
    key: collections[0].id
  }, React.createElement(S.CollectionNameHeader, {
    "data-test": "productCollection"
  }, collections[0].name)) : "", React.createElement(S.SocialShareSelection, null, React.createElement(S.SocialButton, null, React.createElement(FacebookShareButton, {
    url: window.location.href
  }, React.createElement(FacebookIcon, {
    path: "/",
    size: 32,
    bgStyle: {
      fill: "#0D233F"
    }
  }))), React.createElement(S.SocialButton, null, React.createElement(PinterestShareButton, {
    url: window.location.href,
    media: "/images/favicons/favicon-16x16.png"
  }, React.createElement(PinterestIcon, {
    path: "/",
    size: 32,
    bgStyle: {
      fill: "#0D233F"
    }
  }))), React.createElement(S.SocialButton, null, React.createElement(TwitterShareButton, {
    url: window.location.href
  }, React.createElement(TwitterIcon, {
    path: "/",
    size: 32,
    bgStyle: {
      fill: "#0D233F"
    }
  }))), React.createElement(S.SocialButton, null, React.createElement(EmailShareButton, {
    url: window.location.href
  }, React.createElement(EmailIcon, {
    path: "/",
    size: 32,
    bgStyle: {
      fill: "#0D233F"
    }
  })))), isOutOfStock ? renderErrorMessage(intl.formatMessage(commonMessages.outOfStock), "outOfStock") : React.createElement(S.ProductPricing, null, getProductPrice(productPricing, variantPricing)), React.createElement(CustomizeButton, {
    onSubmit: function onSubmit() {
      return setDisplayModalPersonalisation(true);
    },
    disabled: disableButton
  }), noPurchaseAvailable && renderErrorMessage(intl.formatMessage(commonMessages.noPurchaseAvailable), "notAvailable"), purchaseAvailableDate && renderErrorMessage(intl.formatMessage(commonMessages.purchaseAvailableOn, {
    date: new Intl.DateTimeFormat("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    }).format(purchaseAvailableDate),
    time: new Intl.DateTimeFormat("default", {
      hour: "numeric",
      minute: "numeric"
    }).format(purchaseAvailableDate)
  }), "timeRestrictedAvailability"), isLowStock && renderErrorMessage(intl.formatMessage(commonMessages.lowStock), "lowStockWarning"), isNoItemsAvailable && renderErrorMessage(intl.formatMessage(commonMessages.noItemsAvailable), "noItemsAvailable"), React.createElement(S.VariantPicker, null, React.createElement(ProductVariantPicker, {
    productVariants: productVariants,
    onChange: onVariantPickerChange,
    selectSidebar: true,
    queryAttributes: queryAttributes,
    onAttributeChangeHandler: onAttributeChangeHandler
  })), React.createElement(S.QuantityInput, null, React.createElement(QuantityInput, {
    quantity: quantity,
    maxQuantity: availableQuantity,
    disabled: isOutOfStock || isNoItemsAvailable,
    onQuantityChange: setQuantity,
    hideErrors: !variantId || isOutOfStock || isNoItemsAvailable,
    testingContext: "addToCartQuantity"
  })), displayModalPersonalisation && React.createElement(PersonalizationFormModal, {
    hideModal: function hideModal() {
      setDisplayModalPersonalisation(false);
    },
    submitBtnText: intl.formatMessage(commonMessages.save),
    title: intl.formatMessage(checkoutMessages.personalization),
    formId: "personalisation-form"
  }));
};

AddToCartSection.displayName = "AddToCartSection";
export default AddToCartSection;