import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { usePreferences } from "@hooks";
import { localesOptionsShipsTo } from "@temp/components/Locale";
import { Modal } from "../Modal";
import { PreferencesFormShipping } from "../PreferencesFormShipping";
export var PreferencesFormShippingModal = function PreferencesFormShippingModal(_ref) {
  var hideModal = _ref.hideModal,
      submitBtnText = _ref.submitBtnText,
      target = _ref.target,
      title = _ref.title,
      formId = _ref.formId;

  var _React$useState = React.useState(true),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      show = _React$useState2[0],
      setShow = _React$useState2[1];

  var _usePreferences = usePreferences(),
      preferences = _usePreferences.preferences,
      setPreferences = _usePreferences.setPreferences;

  return React.createElement(Modal, {
    submitButtonTestingContext: "submitPreferencesFormModalButton",
    testingContext: "submitPreferencesFormModal",
    title: title,
    hide: function hide() {
      hideModal();
      setShow(false);
    },
    formId: formId,
    disabled: false,
    show: show,
    target: target,
    submitBtnText: submitBtnText
  }, React.createElement(PreferencesFormShipping, {
    preferences: preferences,
    localesOptions: localesOptionsShipsTo,
    formId: formId,
    handleSubmit: function handleSubmit(data) {
      hideModal();
      window.localStorage.setItem("shipsTo", data.locale);
      setPreferences(data);
    }
  }));
};