import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n        display: none;\n      "]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n        display: none;\n      "]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr 1fr;\n  "]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr 1fr;\n  "]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  width: auto;\n  height: auto;\n  max-width: 100%;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n  ", "\n  ", "\n  display: grid;\n  background: #f6f6f6;\n\n  :hover {\n    background: #d1dee6;\n    color: black;\n  }\n\n  > span {\n    grid-column-end: span 2;\n    display: inline-grid !important;\n  }\n\n  > span img {\n    height: auto;\n    max-width: 100%;\n  }\n  > div {\n    h3 {\n      margin-bottom: 10px;\n    }\n\n    p {\n      ", "\n      ", "\n    }\n\n    height: auto;\n    max-width: 100%;\n    grid-column-end: span 2;\n    padding: 10px;\n    overflow: hidden;\n    line-height: 2rem;\n    max-height: 8rem;\n    -webkit-box-orient: vertical;\n    display: block;\n    display: -webkit-box;\n    overflow: hidden !important;\n    text-overflow: ellipsis;\n    -webkit-line-clamp: 4;\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  font-weight: normal;\n  font-family: \"Cardo\", serif;\n  font-style: italic;\n  ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  font-weight: normal;\n  ", "\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n    padding: 0rem;\n  "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  background: #f6f6f6;\n  padding: 0rem;\n  text-align: center;\n  transition: 0.3s;\n\n  ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    text-align: center;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    text-align: center;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  margin: 0 0 0.5rem 0;\n  text-align: center;\n  ", "\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
import { css } from "styled-components";
var textProps = css(_templateObject(), function (props) {
  return props.theme.typography.baseFontSize;
}, media.mediumScreen(_templateObject2()), media.smallScreen(_templateObject3()));
export var Wrapper = styled.div(_templateObject4(), media.largeScreen(_templateObject5()));
export var Title = styled.h4(_templateObject6(), textProps);
export var ArtisanName = styled.p(_templateObject7(), textProps);
export var Price = styled.p(_templateObject8(), textProps);
export var Image = styled.div(_templateObject9(), media.mediumScreen(_templateObject10()), media.smallScreen(_templateObject11()), media.mediumScreen(_templateObject12()), media.smallScreen(_templateObject13()));