import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  query VariantList($ids: [ID!], $countryCode: CountryCode) {\n    productVariants(ids: $ids, first: 100) {\n      edges {\n        node {\n          ...ProductVariantFields\n          product {\n            ...BasicProductFields\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  ", "\n  ", "\n  query ProductDetails($id: ID!, $countryCode: CountryCode) {\n    product(id: $id) {\n      ...BasicProductFields\n      ...ProductPricingField\n      descriptionJson\n      collections {\n        products(last: 3, filter: { isPublished: true }) {\n          edges {\n            node {\n              ...BasicProductFields\n              ...ProductPricingField\n            }\n          }\n        }\n      }\n      category {\n        id\n        name\n        translation(languageCode: EN) {\n          name\n        }\n        backgroundImage {\n          url\n          alt\n        }\n        products(last: 3) {\n          edges {\n            node {\n              ...BasicProductFields\n              ...ProductPricingField\n            }\n          }\n        }\n      }\n      images {\n        id\n        alt\n        url\n      }\n      attributes {\n        ...SelectedAttributeFields\n      }\n      variants {\n        ...ProductVariantFields\n      }\n      seoDescription\n      seoTitle\n      isAvailable\n      isAvailableForPurchase\n      availableForPurchase\n    }\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  fragment ProductVariantFields on ProductVariant {\n    id\n    sku\n    name\n    isAvailable\n    quantityAvailable(countryCode: $countryCode)\n    images {\n      id\n      url\n      alt\n    }\n    pricing {\n      onSale\n      priceUndiscounted {\n        ...Price\n      }\n      price {\n        ...Price\n      }\n    }\n    attributes {\n      attribute {\n        id\n        name\n        slug\n      }\n      values {\n        id\n        name\n        value: name\n      }\n    }\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  fragment SelectedAttributeFields on SelectedAttribute {\n    attribute {\n      id\n      name\n    }\n    values {\n      id\n      name\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  fragment ProductPricingField on Product {\n    pricing {\n      onSale\n      priceRangeUndiscounted {\n        start {\n          ...Price\n        }\n        stop {\n          ...Price\n        }\n      }\n      priceRange {\n        start {\n          ...Price\n        }\n        stop {\n          ...Price\n        }\n      }\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  fragment BasicProductFields on Product {\n    id\n    name\n    translation(languageCode: EN) {\n      name\n      descriptionJson\n    }\n    collections {\n      id\n      name\n      descriptionJson\n      seoTitle\n      seoDescription\n      translation(languageCode: EN) {\n        name\n        descriptionJson\n      }\n      backgroundImage {\n        url\n        alt\n      }\n      metadata {\n        key\n        value\n      }\n    }\n    thumbnail {\n      url\n      alt\n    }\n    thumbnail2x: thumbnail(size: 510) {\n      url\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  fragment Price on TaxedMoney {\n    gross {\n      amount\n      currency\n    }\n    net {\n      amount\n      currency\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
export var priceFragment = gql(_templateObject());
export var basicProductFragment = gql(_templateObject2());
export var productPricingFragment = gql(_templateObject3(), priceFragment);
export var selectedAttributeFragment = gql(_templateObject4());
export var productVariantFragment = gql(_templateObject5(), priceFragment);
export var productDetailsQuery = gql(_templateObject6(), basicProductFragment, selectedAttributeFragment, productVariantFragment, productPricingFragment); // FIXME: Check how to handle pagination of `productVariants` in the UI.
// We need allow the user view  all cart items regardless of pagination.

export var productVariantsQuery = gql(_templateObject7(), basicProductFragment, productVariantFragment);
export var TypedProductDetailsQuery = TypedQuery(productDetailsQuery);
export var TypedProductVariantsQuery = TypedQuery(productVariantsQuery);