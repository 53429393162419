import React from "react";
import { Thumbnail } from "@components/molecules";
import * as S from "./styles";
export var ProductTileSearch = function ProductTileSearch(_ref) {
  var product = _ref.product;
  return React.createElement(S.Wrapper, null, React.createElement(S.Image, {
    "data-test": "productThumbnail"
  }, React.createElement(Thumbnail, {
    source: product
  })), React.createElement(S.Title, {
    "data-test": "ProductTileSearch"
  }, product.name));
};