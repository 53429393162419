import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _extends from "@babel/runtime/helpers/extends";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { TextField } from "@components/molecules/TextField";
import { TextareaField } from "@components/molecules/TextareaField";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";
export var PersonalizationFormContent = function PersonalizationFormContent(_ref) {
  var formRef = _ref.formRef,
      handleChange = _ref.handleChange,
      handleBlur = _ref.handleBlur,
      formId = _ref.formId,
      errors = _ref.errors,
      handleSubmit = _ref.handleSubmit,
      values = _ref.values,
      testingContext = _ref.testingContext;
  var basicInputProps = useCallback(function () {
    return {
      onBlur: handleBlur,
      onChange: handleChange
    };
  }, [handleChange, handleBlur]);
  var intl = useIntl();
  var fieldErrors = {};

  if (errors) {
    errors.map(function (_ref2) {
      var field = _ref2.field,
          message = _ref2.message;
      fieldErrors[field] = fieldErrors[field] ? [].concat(_toConsumableArray(fieldErrors[field]), [{
        message: message
      }]) : [{
        message: message
      }];
    });
  }

  return React.createElement(S.PersonalizationForm, {
    id: formId,
    ref: formRef,
    onSubmit: handleSubmit,
    "data-test": testingContext
  }, React.createElement(S.Wrapper, null, React.createElement(S.RowWithTwoCells, null, React.createElement(TextField, _extends({
    name: "firstName",
    label: intl.formatMessage(commonMessages.firstName),
    value: values.firstName,
    autoComplete: "given-name",
    errors: fieldErrors.firstName
  }, basicInputProps(), {
    required: true
  })), React.createElement(TextField, _extends({
    name: "email",
    label: intl.formatMessage(commonMessages.shortEmail),
    value: values.email,
    autoComplete: "email",
    errors: fieldErrors.email
  }, basicInputProps(), {
    required: true,
    type: "email"
  }))), React.createElement(S.RowWithOneCell, null, React.createElement(TextareaField, _extends({
    name: "description",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Description",
      "id": "@next.components.organisms.PersonalizationForm.PersonalizationFormContent.3374163063"
    }, "id", "@next.components.organisms.PersonalizationForm.PersonalizationFormContent.3374163063")),
    value: values.description,
    autoComplete: "description",
    errors: fieldErrors.description
  }, basicInputProps(), {
    required: true
  })))));
};