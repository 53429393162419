import * as React from "react";
import { Link } from "react-router-dom";
import { ThumbnailCollection } from "@components/molecules";
import { generateCategoryUrl } from "../../../core/utils";

var CategoryItem = function CategoryItem(_ref) {
  var category = _ref.node;
  return React.createElement("li", {
    className: "search__products__item"
  }, React.createElement(Link, {
    to: generateCategoryUrl(category.id, category.name)
  }, React.createElement(ThumbnailCollection, {
    source: category
  }), React.createElement("span", null, React.createElement("h3", null, category.name), React.createElement("p", null, "Category"))));
};

export default CategoryItem;