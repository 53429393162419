import React, { useRef, useEffect } from "react";
import LoadingBar from "react-top-loading-bar";

var LoadingBarLine = function LoadingBarLine() {
  var ref = useRef(null);
  useEffect(function () {
    ref.current.complete();
  }, []);
  return React.createElement(LoadingBar, {
    color: "#0D233F",
    ref: ref,
    loaderSpeed: 500
  });
};

export default LoadingBarLine;