import React from "react";
import { usePreferences } from "@hooks";
import { ThumbnailCollection } from "@components/molecules";
import { maybe } from "@utils/misc";
import { LazyLoadImage } from "react-lazy-load-image-component";
import * as S from "./styles";
export var ProductTileStories = function ProductTileStories(_ref) {
  var _product$thumbnail, _product$collections$;

  var product = _ref.product;

  var _usePreferences = usePreferences(),
      locale = _usePreferences.preferences.locale;

  return React.createElement(S.Wrapper, null, React.createElement(S.WrapperImg, null, React.createElement(S.ImageCollectionItem, null, ((_product$thumbnail = product.thumbnail) === null || _product$thumbnail === void 0 ? void 0 : _product$thumbnail.url) && React.createElement(LazyLoadImage, {
    effect: "blur",
    src: maybe(function () {
      var _product$thumbnail2;

      return (_product$thumbnail2 = product.thumbnail) === null || _product$thumbnail2 === void 0 ? void 0 : _product$thumbnail2.url;
    }),
    alt: maybe(function () {
      var _product$thumbnail3;

      return (_product$thumbnail3 = product.thumbnail) === null || _product$thumbnail3 === void 0 ? void 0 : _product$thumbnail3.alt;
    })
  })), React.createElement(S.Image, {
    "data-test": "productThumbnail"
  }, React.createElement(ThumbnailCollection, {
    source: product.collections[0]
  }))), React.createElement(S.CollectionsName, null, React.createElement("h3", null, locale === "en" && ((_product$collections$ = product.collections[0].translation) === null || _product$collections$ === void 0 ? void 0 : _product$collections$.name) ? product.collections[0].translation.name : product.collections[0].name)));
};