import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import classNames from "classnames";
import * as React from "react";
import ReactSVG from "react-svg";
import { NavLink } from "..";
import subcategoriesImg from "../../images/subcategories.svg";

var NavItem = function NavItem(_ref) {
  var hideOverlay = _ref.hideOverlay,
      showSubItems = _ref.showSubItems,
      item = _objectWithoutProperties(_ref, ["hideOverlay", "showSubItems"]);

  var hasSubNavigation = item.children && !!item.children.length;
  return React.createElement("li", {
    className: classNames({
      "side-nav__menu-item": true,
      "side-nav__menu-item--has-subnavigation": hasSubNavigation
    })
  }, hasSubNavigation ? React.createElement(NavLink, {
    item: item,
    className: "side-nav__menu-item-link",
    onClick: function onClick() {
      return showSubItems(item);
    }
  }) : React.createElement(NavLink, {
    item: item,
    className: "side-nav__menu-item-link",
    onClick: hideOverlay
  }), hasSubNavigation && React.createElement(ReactSVG, {
    path: subcategoriesImg,
    className: "side-nav__menu-item-more",
    onClick: function onClick() {
      return showSubItems(item);
    }
  }));
};

export default NavItem;