import "./scss/index.scss";
import * as React from "react";
import { usePreferences } from "@hooks";
import { Thumbnail } from "@components/molecules";

var ProductListItemCategory = function ProductListItemCategory(_ref) {
  var _category$translation;

  var product = _ref.product;
  var category = product.category;

  var _usePreferences = usePreferences(),
      locale = _usePreferences.preferences.locale;

  return React.createElement("div", {
    className: "product-list-item"
  }, React.createElement("div", {
    className: "product-list-item__image"
  }, React.createElement(Thumbnail, {
    source: product
  })), React.createElement("p", {
    className: "product-list-item__category"
  }, locale === "en" && ((_category$translation = category.translation) === null || _category$translation === void 0 ? void 0 : _category$translation.name) ? category.translation.name : category.name));
};

export default ProductListItemCategory;