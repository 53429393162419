import * as React from "react";
import { SOCIAL_MEDIA } from "../../core/config";
import { MobileNavList, Overlay, SocialMediaIcon } from "..";

var MobileNav = function MobileNav(_ref) {
  var overlay = _ref.overlay;
  var items = overlay.context.data;
  return React.createElement(Overlay, {
    testingContext: "mobileNavigationOverlay",
    context: overlay
  }, React.createElement("div", {
    className: "side-nav",
    onClick: function onClick(evt) {
      return evt.stopPropagation();
    }
  }, React.createElement(MobileNavList, {
    items: items,
    hideOverlay: overlay.hide
  })), React.createElement("div", {
    className: "footer__favicons container"
  }, SOCIAL_MEDIA.map(function (medium) {
    return React.createElement(SocialMediaIcon, {
      medium: medium,
      key: medium.ariaLabel
    });
  })));
};

export default MobileNav;