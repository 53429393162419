import _defineProperty from "@babel/runtime/helpers/defineProperty";
import "./scss/index.scss";
import classNames from "classnames";
import * as React from "react";
import { useEffect } from "react";
import { CarouselProvider, DotGroup, Slider, Slide, Image, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Button, Loader, ProductsFeatured } from "../../components";
import { generateCollectionUrl } from "../../core/utils";
import ArtisanVideo from "./Video";
import { structuredData } from "../../core/SEO/Homepage/structuredData";
import noPhotoImg from "../../images/no-photo.svg";
var srcVideo = "https://player.vimeo.com/video/441781948?title=0&byline=0&portrait=0&loop=1&autopause=0";

var Page = function Page(_ref) {
  var loading = _ref.loading,
      categories = _ref.categories,
      collections = _ref.collections,
      projects = _ref.projects,
      backgroundImage = _ref.backgroundImage,
      shop = _ref.shop;

  var collectionsExist = function collectionsExist() {
    return collections && collections.edges && collections.edges.length > 0;
  };

  var ProjectsExist = function ProjectsExist() {
    return projects && projects.edges && projects.edges.length > 0;
  };

  var intl = useIntl();
  useEffect(function () {
    var blockBlur = document.getElementById("blur");
    if (blockBlur) blockBlur.style.filter = "blur(0px)";
  }, []);
  return React.createElement(React.Fragment, null, React.createElement("script", {
    className: "structured-data-list",
    type: "application/ld+json"
  }, structuredData(shop)), React.createElement("div", {
    className: "home-page__hero",
    id: "blur",
    style: backgroundImage ? {
      backgroundImage: "url(".concat(backgroundImage.url, ")")
    } : null
  }, React.createElement("div", {
    className: "home-page__hero-text"
  }, React.createElement("div", null, React.createElement("span", {
    className: "home-page__hero__title"
  }, React.createElement("h1", null, React.createElement(FormattedMessage, {
    id: "views.Home.Page.1162691464",
    defaultMessage: "Unique Italian"
  })))), React.createElement("div", null, React.createElement("span", {
    className: "home-page__hero__title"
  }, React.createElement("h1", null, React.createElement(FormattedMessage, {
    id: "views.Home.Page.1172910972",
    defaultMessage: "Design & Home D\xE9cor"
  }))))), React.createElement("div", {
    className: "home-page__hero-action"
  }, loading && !collections ? React.createElement(Loader, null) : collectionsExist() && React.createElement(Link, {
    to: generateCollectionUrl(shop.homepageCollection.id, shop.homepageCollection.name)
  }, React.createElement(Button, {
    testingContext: "homepageHeroActionButton"
  }, React.createElement(FormattedMessage, {
    id: "views.Home.Page.3920565939",
    defaultMessage: "Explore Now"
  }))))), React.createElement(ProductsFeatured, {
    title: intl.formatMessage(_defineProperty({
      defaultMessage: "All Categories",
      "id": "views.Home.Page.4294878092"
    }, "id", "views.Home.Page.4294878092"))
  }), collectionsExist() && React.createElement("div", {
    className: "home-page__collections"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement("h3", null, React.createElement(FormattedMessage, {
    id: "views.Home.Page.1051582247",
    defaultMessage: "Latest Stories"
  })), React.createElement("div", {
    className: "home-page__collections__list"
  }, collections.edges.map(function (_ref2) {
    var collection = _ref2.node;
    return React.createElement("div", {
      key: collection.id
    }, React.createElement(Link, {
      to: generateCollectionUrl(collection.id, collection.name),
      key: collection.id
    }, React.createElement("div", {
      className: classNames("home-page__collections__list__image", {
        "home-page__collections__list__image--no-photo": !collection.backgroundImage
      }),
      style: {
        backgroundImage: "url(".concat(collection.backgroundImage ? collection.backgroundImage.url : noPhotoImg, ")")
      }
    }), React.createElement("h3", null, collection.name)));
  })))), React.createElement(ArtisanVideo, {
    srcVideo: srcVideo
  }), ProjectsExist() && React.createElement("div", {
    className: "home-page__collections"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement("h3", null, React.createElement(FormattedMessage, {
    id: "views.Home.Page.4094220121",
    defaultMessage: "Latest Projects"
  })), React.createElement("div", {
    className: "home-page__collections__list"
  }, projects.edges.map(function (_ref3) {
    var project = _ref3.node;
    return React.createElement("div", {
      key: project.id
    }, React.createElement(Link, {
      to: generateCollectionUrl(project.id, project.name),
      key: project.id
    }, React.createElement("div", {
      className: classNames("home-page__collections__list__image", {
        "home-page__collections__list__image--no-photo": !project.backgroundImage
      }),
      style: {
        backgroundImage: "url(".concat(project.backgroundImage ? project.backgroundImage.url : noPhotoImg, ")")
      }
    }), React.createElement("h3", null, project.name)));
  })))), React.createElement("div", {
    className: "container"
  }, React.createElement("h3", null, React.createElement(FormattedMessage, {
    id: "views.Home.Page.3690444615",
    defaultMessage: "Parlano di noi"
  })), React.createElement(CarouselProvider, {
    naturalSlideWidth: 100,
    naturalSlideHeight: 30,
    visibleSlides: 3,
    totalSlides: 3,
    step: 3
  }, React.createElement(Slider, {
    style: {
      background: "#EFF5F8",
      padding: "5%",
      outline: "0px!important"
    },
    className: "homepage-carousel-slider"
  }, React.createElement(Slide, {
    index: 0
  }, React.createElement(Image, {
    hasMasterSpinner: true,
    className: "homepage-carousel-image",
    src: "https://storitalia.s3.eu-west-2.amazonaws.com/actv.jpg"
  })), React.createElement(Slide, {
    className: "homepage-carousel-slide",
    index: 1
  }, React.createElement(Image, {
    hasMasterSpinner: true,
    className: "homepage-carousel-image",
    src: "https://storitalia.s3.eu-west-2.amazonaws.com/vivimilano.svg"
  })), React.createElement(Slide, {
    className: "homepage-carousel-slide",
    index: 2
  }, React.createElement(Image, {
    hasMasterSpinner: true,
    className: "homepage-carousel-image",
    src: "https://storitalia.s3.eu-west-2.amazonaws.com/SVGLogo_livingworking.svg"
  }))), React.createElement(ButtonBack, null, "\u21BC"), React.createElement(ButtonNext, null, "\u21C0"), React.createElement(DotGroup, null))));
};

export default Page;