import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  fragment MainMenuSubItem on MenuItem {\n    id\n    name\n    category {\n      id\n      name\n    }\n    url\n    collection {\n      id\n      name\n    }\n    page {\n      slug\n    }\n    parent {\n      id\n    }\n  }\n\n  query MainMenu {\n    menu(id: \"TWVudTo3\") {\n      items {\n        ...MainMenuSubItem\n        children {\n          ...MainMenuSubItem\n          children {\n            ...MainMenuSubItem\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  fragment MainMenuSubItem on MenuItem {\n    id\n    name\n    category {\n      id\n      name\n    }\n    url\n    collection {\n      id\n      name\n    }\n    page {\n      slug\n    }\n    parent {\n      id\n    }\n  }\n\n  query MainMenu {\n    menu(id: \"TWVudTo2\") {\n      items {\n        ...MainMenuSubItem\n        children {\n          ...MainMenuSubItem\n          children {\n            ...MainMenuSubItem\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  fragment MainMenuSubItem on MenuItem {\n    id\n    name\n    category {\n      id\n      name\n    }\n    url\n    collection {\n      id\n      name\n    }\n    page {\n      slug\n    }\n    parent {\n      id\n    }\n  }\n\n  query MainMenu {\n    menu(id: \"TWVudTo0\") {\n      items {\n        ...MainMenuSubItem\n        children {\n          ...MainMenuSubItem\n          children {\n            ...MainMenuSubItem\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  fragment MainMenuSubItem on MenuItem {\n    id\n    name\n    category {\n      id\n      name\n    }\n    url\n    collection {\n      id\n      name\n    }\n    page {\n      slug\n    }\n    parent {\n      id\n    }\n  }\n\n  query MainMenu {\n    menu(id: \"TWVudTox\") {\n      items {\n        ...MainMenuSubItem\n        children {\n          ...MainMenuSubItem\n          children {\n            ...MainMenuSubItem\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
export var mainMenu = gql(_templateObject());
export var mainMenuEN = gql(_templateObject2());
export var mainMenuPartner = gql(_templateObject3());
export var mainMenuPartnerEN = gql(_templateObject4());
export var TypedMainMenuQuery = TypedQuery(mainMenu);
export var TypedMainMenuQueryEN = TypedQuery(mainMenuEN);
export var TypedMainMenuQueryPartner = TypedQuery(mainMenuPartner);
export var TypedMainMenuQueryPartnerEN = TypedQuery(mainMenuPartnerEN);