import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  font-size: 0.8rem;\n  padding: 0.3rem;\n  margin: 1rem;\n  background-color: white;\n  color: ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  margin: 0;\n  color: white;\n  margin: 2rem 1rem;\n  font-size: 2rem;\n  line-height: 1.8rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: ", ";\n  margin-top: 30px;\n  display: flex;\n  background: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var ErrorMessage = styled.div(_templateObject(), function (props) {
  return props.theme.colors.error;
}, function (props) {
  return props.theme.input.labelFontSize;
}, function (props) {
  return props.theme.colors.error;
});
export var ErrorParagraph = styled.p(_templateObject2());
export var ErrorLabel = styled.p(_templateObject3(), function (props) {
  return props.theme.colors.error;
});
ErrorMessage.displayName = "S.ErrorMessage";