import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import { FacebookShareButton, PinterestShareButton, TwitterShareButton, EmailShareButton, FacebookIcon, PinterestIcon, TwitterIcon, EmailIcon } from "react-share";
import * as React from "react";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import { useIntl } from "react-intl";
import { RichTextContent } from "@components/atoms";
import { commonMessages } from "@temp/intl";
import { usePreferences } from "@hooks";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ProductListHeader, ProductListHeaderStories, ProductListHeaderProjects } from "../../@next/components/molecules";
import { ProductList, ProductListStories, ProductListProjects } from "../../@next/components/organisms";
import { ProductsFeatured } from "../../components";
import { maybe } from "../../core/utils";
import { FilterSidebar } from "../../@next/components/organisms/FilterSidebar";
import SimpleMap from "./SimpleMap";
import ArtisanVideo from "./Video";
import * as S from "./styles";

var Page = function Page(_ref) {
  var _collection$translati, _collection$translati2, _collection$translati3, _collection$translati4;

  var activeFilters = _ref.activeFilters,
      activeSortOption = _ref.activeSortOption,
      attributes = _ref.attributes,
      collection = _ref.collection,
      displayLoader = _ref.displayLoader,
      hasNextPage = _ref.hasNextPage,
      clearFilters = _ref.clearFilters,
      onLoadMore = _ref.onLoadMore,
      products = _ref.products,
      filters = _ref.filters,
      onOrder = _ref.onOrder,
      sortOptions = _ref.sortOptions,
      onAttributeFiltersChange = _ref.onAttributeFiltersChange;
  var canDisplayProducts = maybe(function () {
    return !!products.edges && products.totalCount !== undefined;
  });
  var hasProducts = canDisplayProducts && !!products.totalCount;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showFilters = _React$useState2[0],
      setShowFilters = _React$useState2[1];

  var intl = useIntl();

  var _usePreferences = usePreferences(),
      locale = _usePreferences.preferences.locale;

  var getAttribute = function getAttribute(attributeSlug, valueSlug) {
    return {
      attributeSlug: attributeSlug,
      valueName: attributes.find(function (_ref2) {
        var slug = _ref2.slug;
        return attributeSlug === slug;
      }).values.find(function (_ref3) {
        var slug = _ref3.slug;
        return valueSlug === slug;
      }).name,
      valueSlug: valueSlug
    };
  };

  var activeFiltersAttributes = filters && filters.attributes && Object.keys(filters.attributes).reduce(function (acc, key) {
    return acc.concat(filters.attributes[key].map(function (valueSlug) {
      return getAttribute(key, valueSlug);
    }));
  }, []);
  var activeFiltersAttributesStories = filters && filters.attributes && Object.keys(filters.attributes).reduce(function (acc, key) {
    return acc.concat(filters.attributes[key].map(function (valueSlug) {
      return getAttribute(key, valueSlug);
    }));
  }, []);
  var MetaBackground = collection.metadata.find(function (element) {
    return element.key === "back_img";
  });
  var VertImg_1 = collection.metadata.find(function (element) {
    return element.key.split(": ")[0] === "vert_img_1";
  });
  var VertImg_1Alt = VertImg_1 ? VertImg_1.key.split(": ")[1] : null;
  var VertImg_2 = collection.metadata.find(function (element) {
    return element.key.split(": ")[0] === "vert_img_2";
  });
  var VertImg_2Alt = VertImg_2 ? VertImg_2.key.split(": ")[1] : null;
  var HorImg = collection.metadata.find(function (element) {
    return element.key.split(": ")[0] === "hor_img";
  });
  var HorImg_Alt = HorImg ? HorImg.key.split(": ")[1] : null;
  var MetaVideo = collection.metadata.find(function (element) {
    return element.key === "vimeo_id";
  });
  var MetaMap = collection.metadata.find(function (element) {
    return element.key === "google_maps";
  });
  var srcVideo = MetaVideo ? "https://player.vimeo.com/video/".concat(MetaVideo.value, "?title=0&byline=0&portrait=0&loop=1&autopause=0") : "";
  return React.createElement("div", {
    className: "collection"
  }, MetaBackground && React.createElement("div", {
    className: "article-page__header",
    style: MetaBackground.value ? {
      backgroundImage: "url(".concat(MetaBackground.value, ")")
    } : null
  }, React.createElement("span", {
    className: "article-page__header__title"
  }, React.createElement("h1", null, locale === "en" && ((_collection$translati = collection.translation) === null || _collection$translati === void 0 ? void 0 : _collection$translati.name) ? collection.translation.name : collection.name))), React.createElement("div", {
    className: "container"
  }, canDisplayProducts && !MetaBackground && collection.id !== "Q29sbGVjdGlvbjo0OQ==" && collection.id !== "Q29sbGVjdGlvbjo1NA==" ? React.createElement("div", {
    className: "collection__container"
  }, React.createElement("div", {
    className: "collection__image"
  }, React.createElement(LazyLoadImage, {
    src: collection.backgroundImage.url,
    alt: collection.slug,
    effect: "blur"
  })), React.createElement("div", {
    className: "collection__content"
  }, React.createElement(S.SocialShareSelection, null, React.createElement(S.SocialButton, null, React.createElement(FacebookShareButton, {
    url: window.location.href
  }, React.createElement(FacebookIcon, {
    path: "/",
    size: 32,
    bgStyle: {
      fill: "#0D233F"
    }
  }))), React.createElement(S.SocialButton, null, React.createElement(PinterestShareButton, {
    url: window.location.href,
    media: "/images/favicons/favicon-16x16.png"
  }, React.createElement(PinterestIcon, {
    path: "/",
    size: 32,
    bgStyle: {
      fill: "#0D233F"
    }
  }))), React.createElement(S.SocialButton, null, React.createElement(TwitterShareButton, {
    url: window.location.href
  }, React.createElement(TwitterIcon, {
    path: "/",
    size: 32,
    bgStyle: {
      fill: "#0D233F"
    }
  }))), React.createElement(S.SocialButton, null, React.createElement(EmailShareButton, {
    url: window.location.href
  }, React.createElement(EmailIcon, {
    path: "/",
    size: 32,
    bgStyle: {
      fill: "#0D233F"
    }
  })))), React.createElement("h3", null, locale === "en" && ((_collection$translati2 = collection.translation) === null || _collection$translati2 === void 0 ? void 0 : _collection$translati2.name) ? collection.translation.name : collection.name), React.createElement(RichTextContent, {
    descriptionJson: locale === "en" && ((_collection$translati3 = collection.translation) === null || _collection$translati3 === void 0 ? void 0 : _collection$translati3.descriptionJson) ? collection.translation.descriptionJson : collection.descriptionJson
  }))) : React.createElement("div", {
    className: "collection-blog"
  }, collection.id !== "Q29sbGVjdGlvbjo0OQ==" && collection.id !== "Q29sbGVjdGlvbjo1NA==" && React.createElement(RichTextContent, {
    descriptionJson: locale === "en" && ((_collection$translati4 = collection.translation) === null || _collection$translati4 === void 0 ? void 0 : _collection$translati4.descriptionJson) ? collection.translation.descriptionJson : collection.descriptionJson
  }), React.createElement("div", {
    className: "home-page__collections"
  }, React.createElement("div", {
    className: "home-page__collections__list"
  }, VertImg_1 && React.createElement("div", {
    key: VertImg_1.key
  }, React.createElement(LazyLoadImage, {
    alt: VertImg_1Alt,
    effect: "blur",
    src: VertImg_1.value
  }), React.createElement("b", null, VertImg_1Alt)), VertImg_2 && React.createElement("div", {
    key: VertImg_2.key
  }, React.createElement(LazyLoadImage, {
    alt: VertImg_2Alt,
    effect: "blur",
    src: VertImg_2.value
  }), React.createElement("b", null, VertImg_2Alt)), HorImg && React.createElement("div", {
    key: HorImg.key
  }, React.createElement(LazyLoadImage, {
    alt: HorImg_Alt,
    effect: "blur",
    src: HorImg.value
  }), React.createElement("b", null, HorImg_Alt))))), MetaMap ? React.createElement(SimpleMap, null) : "", React.createElement(FilterSidebar, {
    show: showFilters,
    hide: function hide() {
      return setShowFilters(false);
    },
    onAttributeFiltersChange: onAttributeFiltersChange,
    attributes: attributes,
    filters: filters
  }), canDisplayProducts && collection.id !== "Q29sbGVjdGlvbjo0OQ==" && collection.id !== "Q29sbGVjdGlvbjo1NA==" ? React.createElement(ProductListHeader, {
    activeSortOption: activeSortOption,
    openFiltersMenu: function openFiltersMenu() {
      return setShowFilters(true);
    },
    numberOfProducts: products ? products.totalCount : 0,
    activeFilters: activeFilters,
    activeFiltersAttributes: activeFiltersAttributes,
    clearFilters: clearFilters,
    sortOptions: sortOptions,
    onChange: onOrder,
    onCloseFilterAttribute: onAttributeFiltersChange
  }) : collection.id === "Q29sbGVjdGlvbjo0OQ==" ? React.createElement(ProductListHeaderStories, {
    activeSortOption: activeSortOption,
    openFiltersMenu: function openFiltersMenu() {
      return setShowFilters(true);
    },
    numberOfProducts: products ? products.totalCount : 0,
    activeFilters: activeFilters,
    activeFiltersAttributes: activeFiltersAttributesStories,
    clearFilters: clearFilters,
    sortOptions: sortOptions,
    onChange: onOrder,
    onCloseFilterAttribute: onAttributeFiltersChange
  }) : React.createElement(ProductListHeaderProjects, {
    activeSortOption: activeSortOption,
    openFiltersMenu: function openFiltersMenu() {
      return setShowFilters(true);
    },
    numberOfProducts: products ? products.totalCount : 0,
    activeFilters: activeFilters,
    activeFiltersAttributes: activeFiltersAttributesStories,
    clearFilters: clearFilters,
    sortOptions: sortOptions,
    onChange: onOrder,
    onCloseFilterAttribute: onAttributeFiltersChange
  }), MetaVideo ? React.createElement(ArtisanVideo, {
    srcVideo: srcVideo
  }) : "", canDisplayProducts && collection.id !== "Q29sbGVjdGlvbjo0OQ==" && collection.id !== "Q29sbGVjdGlvbjo1NA==" ? React.createElement(ProductList, {
    products: products.edges.map(function (edge) {
      return edge.node;
    }),
    canLoadMore: hasNextPage,
    loading: displayLoader,
    onLoadMore: onLoadMore
  }) : collection.id === "Q29sbGVjdGlvbjo0OQ==" ? React.createElement(ProductListStories, {
    products: products.edges.map(function (edge) {
      return edge.node;
    }),
    canLoadMore: hasNextPage,
    loading: displayLoader,
    onLoadMore: onLoadMore
  }) : React.createElement(ProductListProjects, {
    products: products.edges.map(function (edge) {
      return edge.node;
    }),
    canLoadMore: hasNextPage,
    loading: displayLoader,
    onLoadMore: onLoadMore
  })), !hasProducts && React.createElement(ProductsFeatured, {
    title: intl.formatMessage(commonMessages.youMightLike)
  }), React.createElement(ScrollUpButton, {
    style: {
      width: 30,
      backgroundColor: "#ffffff00",
      fill: "#c4c4c4",
      outline: 0
    },
    ToggledStyle: {
      right: 50,
      bottom: 50
    }
  }));
};

export default Page;