import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _localeData, _localeNames, _localeFlag, _localeNamesShipsTo, _localeFlagShipsTo;

import React from "react";
import { IntlProvider } from "react-intl";
import { usePreferences } from "@hooks";
import { Locale } from "@types";
import locale_IT from "@locale/it.json";
var localeData = (_localeData = {}, _defineProperty(_localeData, Locale.EN, undefined), _defineProperty(_localeData, Locale.IT, locale_IT), _localeData);
export var localeNames = (_localeNames = {}, _defineProperty(_localeNames, Locale.EN, "English"), _defineProperty(_localeNames, Locale.IT, "Italiano"), _localeNames);
export var localeFlag = (_localeFlag = {}, _defineProperty(_localeFlag, Locale.EN, "GB"), _defineProperty(_localeFlag, Locale.IT, "IT"), _localeFlag);
export var localeNamesShipsTo = (_localeNamesShipsTo = {}, _defineProperty(_localeNamesShipsTo, Locale.EN, "Worldwide"), _defineProperty(_localeNamesShipsTo, Locale.IT, "Italy"), _localeNamesShipsTo);
export var localeFlagShipsTo = (_localeFlagShipsTo = {}, _defineProperty(_localeFlagShipsTo, Locale.EN, "WW"), _defineProperty(_localeFlagShipsTo, Locale.IT, "IT"), _localeFlagShipsTo);
export var localesOptions = Object.keys(localeNames).map(function (locale) {
  return {
    localeCode: locale,
    localeName: localeNames[locale]
  };
});
export var localesOptionsShipsTo = Object.keys(localeNamesShipsTo).map(function (locale) {
  return {
    localeCode: locale,
    localeName: localeNamesShipsTo[locale]
  };
});
var dotSeparator = "_dot_";
var sepRegExp = new RegExp(dotSeparator, "g");

function getKeyValueJson(messages) {
  if (messages) {
    var keyValueMessages = {};
    return Object.entries(messages).reduce(function (acc, _ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          id = _ref2[0],
          msg = _ref2[1];

      acc[id.replace(sepRegExp, ".")] = msg.string;
      return acc;
    }, keyValueMessages);
  }
}

var LocaleProvider = function LocaleProvider(_ref3) {
  var children = _ref3.children;

  var _usePreferences = usePreferences(),
      locale = _usePreferences.preferences.locale;

  return React.createElement(IntlProvider, {
    defaultLocale: locale,
    locale: locale,
    messages: getKeyValueJson(localeData[locale]),
    key: locale
  }, children);
};

export { LocaleProvider };