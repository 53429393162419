import * as React from "react";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import { usePreferences } from "@hooks";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { RichTextContent } from "@components/atoms";
import { Breadcrumbs, LoadingBarLine } from "../../components";
import ArtisanVideo from "./Video";
export var Page = function Page(_ref) {
  var _page$translation, _page$translation2;

  var breadcrumbs = _ref.breadcrumbs,
      headerImage = _ref.headerImage,
      navigation = _ref.navigation,
      page = _ref.page;

  var _usePreferences = usePreferences(),
      locale = _usePreferences.preferences.locale;

  var MetaBackground = page.metadata.find(function (element) {
    return element.key === "back_img";
  });
  var VertImg_1 = page.metadata.find(function (element) {
    return element.key.split(": ")[0] === "vert_img_1";
  });
  var VertImg_1Alt = VertImg_1 ? VertImg_1.key.split(": ")[1] : null;
  var VertImg_2 = page.metadata.find(function (element) {
    return element.key.split(": ")[0] === "vert_img_2";
  });
  var VertImg_2Alt = VertImg_2 ? VertImg_2.key.split(": ")[1] : null;
  var HorImg = page.metadata.find(function (element) {
    return element.key.split(": ")[0] === "hor_img";
  });
  var HorImg_Alt = HorImg ? HorImg.key.split(": ")[1] : null;
  var MetaVideo = page.metadata.find(function (element) {
    return element.key === "vimeo_id";
  });
  var srcVideo = MetaVideo ? "https://player.vimeo.com/video/".concat(MetaVideo.value, "?title=0&byline=0&portrait=0&loop=1&autopause=0") : "";
  React.useEffect(function () {
    var blockBlur = document.getElementById("blur");
    if (blockBlur) blockBlur.style.filter = "blur(0px)";
  }, []);
  return React.createElement("div", {
    className: "article-page"
  }, React.createElement(LoadingBarLine, null), React.createElement("div", {
    className: "article-page__header",
    id: "blur",
    style: MetaBackground ? {
      backgroundImage: "url(".concat(MetaBackground.value, ")")
    } : null
  }, React.createElement("span", {
    className: "article-page__header__title"
  }, React.createElement("h1", null, locale === "en" && ((_page$translation = page.translation) === null || _page$translation === void 0 ? void 0 : _page$translation.title) ? page.translation.title : page.title))), React.createElement("div", {
    className: "container"
  }, React.createElement(Breadcrumbs, {
    breadcrumbs: breadcrumbs
  }), React.createElement("div", {
    className: "article-page__container"
  }, React.createElement("div", {
    className: "article-page__navigation"
  }), React.createElement("div", {
    className: "article-page__content"
  }, React.createElement(RichTextContent, {
    descriptionJson: locale === "en" && ((_page$translation2 = page.translation) === null || _page$translation2 === void 0 ? void 0 : _page$translation2.contentJson) ? page.translation.contentJson : page.contentJson
  })))), MetaVideo ? React.createElement(ArtisanVideo, {
    srcVideo: srcVideo
  }) : "", React.createElement("div", {
    className: "home-page__collections"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "home-page__collections__list"
  }, VertImg_1 && React.createElement("div", {
    key: VertImg_1.key
  }, React.createElement(LazyLoadImage, {
    alt: VertImg_1Alt,
    effect: "blur",
    src: VertImg_1.value
  }), React.createElement("b", null, VertImg_1Alt)), VertImg_2 && React.createElement("div", {
    key: VertImg_2.key
  }, React.createElement(LazyLoadImage, {
    alt: VertImg_2Alt,
    effect: "blur",
    src: VertImg_2.value
  }), React.createElement("b", null, VertImg_2Alt)), HorImg && React.createElement("div", {
    key: HorImg.key
  }, React.createElement(LazyLoadImage, {
    alt: HorImg_Alt,
    effect: "blur",
    src: HorImg.value
  }), React.createElement("b", null, HorImg_Alt))))), React.createElement(ScrollUpButton, {
    style: {
      width: 30,
      backgroundColor: "#ffffff00",
      fill: "#c4c4c4",
      outline: 0
    },
    ToggledStyle: {
      right: 50,
      bottom: 50
    }
  }));
};
export default Page;