import React from "react";
import { usePreferences } from "@hooks";
import { RichTextContent } from "@components/atoms";
import { ThumbnailCollection } from "@components/molecules";
import * as S from "./styles";
export var ProductTileProjects = function ProductTileProjects(_ref) {
  var _product$collections$, _product$collections$2;

  var product = _ref.product;

  var _usePreferences = usePreferences(),
      locale = _usePreferences.preferences.locale;

  return React.createElement(S.Wrapper, null, React.createElement(S.Image, {
    "data-test": "productThumbnail"
  }, React.createElement(ThumbnailCollection, {
    source: product.collections[1]
  }), React.createElement("div", null, React.createElement("h3", null, locale === "en" && ((_product$collections$ = product.collections[1].translation) === null || _product$collections$ === void 0 ? void 0 : _product$collections$.name) ? product.collections[1].translation.name : product.collections[1].name), React.createElement(RichTextContent, {
    descriptionJson: locale === "en" && ((_product$collections$2 = product.collections[1].translation) === null || _product$collections$2 === void 0 ? void 0 : _product$collections$2.descriptionJson) ? product.collections[1].translation.descriptionJson : product.collections[1].descriptionJson
  }))));
};