import _extends from "@babel/runtime/helpers/extends";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import NukaCarousel from "nuka-carousel";
import * as React from "react";
import Media from "react-media";
import ReactSVG from "react-svg";
import arrowImg from "../../images/carousel-arrow.svg";
import { mediumScreen, smallScreen } from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";

var Carousel = function Carousel(_ref) {
  var children = _ref.children,
      rest = _objectWithoutProperties(_ref, ["children"]);

  var settings = _objectSpread({
    className: "carousel",
    renderBottomCenterControls: function renderBottomCenterControls() {
      return null;
    },
    renderCenterLeftControls: function renderCenterLeftControls(_ref2) {
      var previousSlide = _ref2.previousSlide,
          currentSlide = _ref2.currentSlide;
      return currentSlide !== 0 ? React.createElement("div", {
        onClick: previousSlide,
        className: "carousel__control carousel__control--left"
      }, React.createElement(ReactSVG, {
        path: arrowImg
      })) : null;
    },
    renderCenterRightControls: function renderCenterRightControls(_ref3) {
      var nextSlide = _ref3.nextSlide,
          currentSlide = _ref3.currentSlide,
          slideCount = _ref3.slideCount,
          slidesToShow = _ref3.slidesToShow;
      return slideCount - slidesToShow !== currentSlide ? React.createElement("div", {
        onClick: nextSlide,
        className: "carousel__control carousel__control--right"
      }, React.createElement(ReactSVG, {
        path: arrowImg
      })) : null;
    }
  }, rest);

  var carousel = function carousel(slides) {
    return React.createElement(NukaCarousel, _extends({
      slidesToShow: slides,
      slidesToScroll: slides
    }, settings), children);
  };

  return React.createElement(Media, {
    query: {
      maxWidth: smallScreen
    }
  }, function (matches) {
    return matches ? carousel(1) : React.createElement(Media, {
      query: {
        maxWidth: mediumScreen
      }
    }, function (matches) {
      return carousel(matches ? 3 : 5);
    });
  });
};

export default Carousel;