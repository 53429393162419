import * as React from "react";

var ArtisanVideo = function ArtisanVideo(_ref) {
  var srcVideo = _ref.srcVideo;
  return React.createElement("div", {
    className: "collection-page__video"
  }, React.createElement("iframe", {
    title: "Vimeo",
    src: srcVideo,
    width: "640",
    height: "360",
    allow: "autoplay; fullscreen"
  }));
};

export default ArtisanVideo;