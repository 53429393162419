import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { Formik } from "formik";
import React from "react";
import { PersonalizationFormContent } from "./PersonalizationFormContent";
export var PersonalizationForm = function PersonalizationForm(_ref) {
  var handleSubmit = _ref.handleSubmit,
      formId = _ref.formId,
      props = _objectWithoutProperties(_ref, ["handleSubmit", "formId"]);

  return React.createElement(Formik, {
    initialValues: {
      firstName: "",
      email: "",
      description: ""
    },
    enableReinitialize: true,
    onSubmit: function onSubmit(values, _ref2) {
      var setSubmitting = _ref2.setSubmitting;

      if (handleSubmit) {
        handleSubmit(values);
      }

      setSubmitting(false);
    }
  }, function (_ref3) {
    var handleChange = _ref3.handleChange,
        handleSubmit = _ref3.handleSubmit,
        handleBlur = _ref3.handleBlur,
        values = _ref3.values,
        setFieldTouched = _ref3.setFieldTouched;
    return React.createElement(PersonalizationFormContent, _extends({
      formId: formId,
      handleBlur: handleBlur,
      handleChange: handleChange,
      handleSubmit: handleSubmit,
      setFieldTouched: setFieldTouched,
      values: values
    }, props));
  });
};