import React from "react";
import { usePreferences } from "@hooks";
import { TaxedMoney } from "@components/containers";
import { Thumbnail } from "@components/molecules";
import * as S from "./styles";
export var ProductTile = function ProductTile(_ref) {
  var _product$translation, _product$collections$;

  var product = _ref.product;
  var price = product.pricing && product.pricing.priceRange && product.pricing.priceRange.start ? product.pricing.priceRange.start : undefined;

  var _usePreferences = usePreferences(),
      locale = _usePreferences.preferences.locale;

  return React.createElement(S.Wrapper, null, React.createElement(S.Image, {
    "data-test": "productThumbnail"
  }, React.createElement(Thumbnail, {
    source: product
  })), React.createElement(S.Title, {
    "data-test": "productTile"
  }, locale === "en" && ((_product$translation = product.translation) === null || _product$translation === void 0 ? void 0 : _product$translation.name) ? product.translation.name : product.name), React.createElement(S.ArtisanName, null, locale === "en" && ((_product$collections$ = product.collections[0].translation) === null || _product$collections$ === void 0 ? void 0 : _product$collections$.name) ? product.collections[0].translation.name : product.collections[0].name), React.createElement(S.Price, {
    "data-test": "productPrice"
  }, React.createElement(TaxedMoney, {
    taxedMoney: price
  })));
};