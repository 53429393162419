import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  font-size: ", ";\n  text-transform: uppercase;\n  font-weight: ", ";\n  line-height: ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  box-shadow: inset 0px 0px 0px 3px\n    ", ";\n  border-left: 1px solid ", ";\n  border-right: 1px solid ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    padding:  0.9rem 1rem;\n    width: ", ";\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  padding: ", ";\n  border: none;\n  transition: 0.3s;\n  outline: none;\n  cursor: pointer;\n  color: ", ";\n  width: ", "\n\n  &:hover {\n    background-color: ", ";\n    color: ", ";\n  }\n\n  &:active {\n    background-color: ", ";\n    box-shadow: -3px 3px 14px 0px rgba(129, 67, 67, 0.2);\n  }\n\n  &:disabled {\n    background-color: ", ";\n\n    &,\n    &:hover {\n      cursor: default;\n    }\n  }\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
var padding = {
  md: "1.5rem 3.7rem",
  sm: "0.1rem 2rem"
};

var fontSize = function fontSize(_fontSize, smallFontSize) {
  return {
    md: _fontSize,
    sm: smallFontSize
  };
};

export var Primary = styled.button(_templateObject(), function (props) {
  return props.theme.button.colors[props.color].background;
}, function (props) {
  return padding[props.size];
}, function (props) {
  return props.theme.button.colors[props.color].color;
}, function (props) {
  return props.fullWidth ? "100%" : "auto";
}, function (props) {
  return props.theme.button.colors[props.color].hoverBackground;
}, function (props) {
  return props.theme.button.colors[props.color].hoverColor;
}, function (props) {
  return props.theme.button.colors[props.color].activeBackground;
}, function (props) {
  return props.theme.colors.disabled;
}, media.smallScreen(_templateObject2(), function (props) {
  return props.fullWidth ? "100%" : "88%";
}));
export var Secondary = styled(Primary)(_templateObject3(), function (props) {
  return props.theme.button.colors.secondary.color;
}, function (props) {
  return props.theme.button.colors.secondary.color;
}, function (props) {
  return props.theme.button.colors.secondary.color;
});
export var Text = styled.span(_templateObject4(), function (_ref) {
  var size = _ref.size,
      typography = _ref.theme.button.typography;
  return fontSize(typography.fontSize, typography.smallFontSize)[size];
}, function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.typography.baseLineHeight;
});