import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  padding: 1.5rem 1rem;\n  margin: 0;\n  height: 120px;\n  border: none;\n  width: 100%;\n  font-size: ", ";\n  outline: none;\n  background-color: transparent;\n  &:-webkit-autofill {\n    border-radius: 5px;\n    & + label {\n      ", ";\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  width: 100%;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  border: 1px solid ", ";\n  color: ", ";\n  outline: ", ";\n  transition: all 0.3s ease;\n  outline-style: none;\n\n  &:hover {\n    color: ", ";\n    outline-width: ", "px;\n    outline-style: none;\n    border-color: ", ";\n    outline-color: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import { activeLabelStyles } from "../InputLabel";

var getEdgeColor = function getEdgeColor(_ref) {
  var active = _ref.active,
      error = _ref.error,
      disabled = _ref.disabled,
      theme = _ref.theme;
  var hovered = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (disabled) {
    return theme.colors.disabled;
  }

  if (error) {
    return theme.colors.error;
  }

  if (hovered) {
    return theme.colors.secondary;
  }

  return active ? theme.colors.secondary : theme.colors.dark;
};

export var Wrapper = styled.div(_templateObject(), function (props) {
  return getEdgeColor(props);
}, function (props) {
  return getEdgeColor(props);
}, function (props) {
  return props.active ? "2px solid ".concat(getEdgeColor(props), ";") : "none";
}, function (props) {
  return getEdgeColor(props, true);
}, function (props) {
  return props.disabled ? 0 : 1;
}, function (props) {
  return getEdgeColor(props, true);
}, function (props) {
  return getEdgeColor(props, true);
});
export var Content = styled.span(_templateObject2());
export var TextareaWrapper = styled.div(_templateObject3());
export var Textarea = styled.textarea(_templateObject4(), function (props) {
  return props.theme.typography.baseFontSize;
}, function (props) {
  return activeLabelStyles(props.theme, props.labelBackground);
});