import "./scss/index.scss";
import CookieConsent from "react-cookie-consent";
import { usePreferences } from "@hooks";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { SocialMediaIcon } from "..";
import { SOCIAL_MEDIA } from "../../core/config";
import Nav from "./Nav";
import NavPartner from "./NavPartner";
import NavEN from "./NavEN";
import NavPartnerEN from "./NavPartnerEN";

var Footer = function Footer() {
  var _usePreferences = usePreferences(),
      locale = _usePreferences.preferences.locale;

  return React.createElement("div", {
    className: "footer",
    id: "footer"
  }, React.createElement("div", {
    className: "footer__favicons container"
  }, SOCIAL_MEDIA.map(function (medium) {
    return React.createElement(SocialMediaIcon, {
      medium: medium,
      key: medium.ariaLabel
    });
  })), locale === "en" && process.env.COLLECTION_ID === "false" ? React.createElement(NavEN, null) : locale === "en" && process.env.COLLECTION_ID ? React.createElement(NavPartnerEN, null) : locale !== "en" && process.env.COLLECTION_ID === "false" ? React.createElement(Nav, null) : React.createElement(NavPartner, null), locale === "en" ? React.createElement(CookieConsent, {
    location: "bottom",
    buttonText: "Accept",
    cookieName: "myAwesomeCookieName25",
    style: {
      background: "#fafafa",
      textAlign: "center"
    },
    contentStyle: {
      margin: "10px"
    },
    expires: 30,
    buttonStyle: {
      color: "#ffffff",
      fontSize: "15px",
      background: "#0D233F",
      flex: "0.3 0 auto",
      margin: "20px",
      padding: "15px 10px"
    }
  }, React.createElement("span", {
    style: {
      fontSize: "0.9rem",
      color: "#000000"
    }
  }, React.createElement(FormattedMessage, {
    id: "components.Footer.Footer.4285131679",
    defaultMessage: "By accepting our policy, you help us to deliver a better website experience. To see our full privacy policy"
  }), React.createElement("a", {
    href: "/page/privacy-cookie-policies/"
  }, React.createElement(FormattedMessage, {
    id: "components.Footer.Footer.1875917796",
    defaultMessage: " Click here"
  })))) : React.createElement(CookieConsent, {
    location: "bottom",
    buttonText: "Accetto",
    cookieName: "myAwesomeCookieName25",
    style: {
      background: "#fafafa",
      textAlign: "center"
    },
    contentStyle: {
      margin: "10px"
    },
    expires: 30,
    buttonStyle: {
      color: "#ffffff",
      fontSize: "15px",
      background: "#0D233F",
      flex: "0.3 0 auto",
      margin: "20px",
      padding: "15px 10px"
    }
  }, React.createElement("span", {
    style: {
      fontSize: "0.9rem",
      color: "#000000"
    }
  }, React.createElement(FormattedMessage, {
    id: "components.Footer.Footer.30547410",
    defaultMessage: "Accettando i nostri termini d'uso, ci aiuti a fornire un'esperienza personalizzata.  Visualizza i termini"
  }), React.createElement("a", {
    href: "/page/privacy-cookie-policies/"
  }, React.createElement(FormattedMessage, {
    id: "components.Footer.Footer.2668869380",
    defaultMessage: " Clicca qui"
  })))));
};

export default Footer;