import * as React from "react";
import { Link } from "react-router-dom";
import { ThumbnailCollection } from "@components/molecules";
import { generateCollectionUrl } from "../../../core/utils";

var CollectionItem = function CollectionItem(_ref) {
  var collection = _ref.node;
  return React.createElement("li", {
    className: "search__products__item"
  }, React.createElement(Link, {
    to: generateCollectionUrl(collection.id, collection.name)
  }, React.createElement(ThumbnailCollection, {
    source: collection
  }), React.createElement("span", null, React.createElement("h3", null, collection.name), React.createElement("p", null, "Collection"))));
};

export default CollectionItem;