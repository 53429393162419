import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { Formik } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import { InputSelect } from "@components/molecules";
import { commonMessages } from "@temp/intl";
import { localeNamesShipsTo } from "@temp/components/Locale";
import * as S from "./styles";
export var PreferencesFormShipping = function PreferencesFormShipping(_ref) {
  var preferences = _ref.preferences,
      handleSubmit = _ref.handleSubmit,
      formId = _ref.formId,
      localesOptions = _ref.localesOptions,
      testingContext = _ref.testingContext,
      formRef = _ref.formRef;
  var intl = useIntl();

  var localeCode = preferences.locale,
      commonFields = _objectWithoutProperties(preferences, ["locale"]);

  var formPreferences = _objectSpread({
    locale: {
      localeCode: localeCode,
      localeName: localeNamesShipsTo[localeCode]
    }
  }, commonFields);

  return React.createElement(Formik, {
    initialValues: formPreferences,
    onSubmit: function onSubmit(values, _ref2) {
      var setSubmitting = _ref2.setSubmitting;

      if (handleSubmit) {
        var locale = values.locale,
            _commonFields = _objectWithoutProperties(values, ["locale"]);

        handleSubmit(_objectSpread({
          locale: locale.localeCode
        }, _commonFields));
      }

      setSubmitting(true);
    }
  }, function (_ref3) {
    var handleSubmit = _ref3.handleSubmit,
        values = _ref3.values,
        setFieldValue = _ref3.setFieldValue;
    return React.createElement(S.LocaleSelectForm, {
      id: formId,
      onSubmit: handleSubmit,
      ref: formRef,
      "data-test": testingContext
    }, React.createElement(S.Wrapper, null, React.createElement(S.RowWithOneCell, null, React.createElement(InputSelect, {
      defaultValue: preferences === null || preferences === void 0 ? void 0 : preferences.locale,
      label: intl.formatMessage(commonMessages.shippingto),
      name: "locale",
      options: localesOptions,
      value: localesOptions === null || localesOptions === void 0 ? void 0 : localesOptions.find(function (option) {
        return option.localeCode === values.locale.localeCode;
      }),
      onChange: function onChange(value, name) {
        return setFieldValue(name, value);
      },
      optionLabelKey: "localeName",
      optionValueKey: "localeCode",
      autoComplete: "locale"
    }))));
  });
};