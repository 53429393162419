import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import * as React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { usePreferences } from "@hooks";
import { commonMessages } from "@temp/intl";
import { localeNames, localeFlag, localeFlagShipsTo } from "@temp/components/Locale";
import { PreferencesFormModal, PreferencesFormShippingModal } from "@components/organisms";

var LocaleSelect = function LocaleSelect() {
  var intl = useIntl();

  var _usePreferences = usePreferences(),
      locale = _usePreferences.preferences.locale;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      displayModal = _React$useState2[0],
      setDisplayModal = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      displayModalShipsTo = _React$useState4[0],
      setDisplayModalShipsTo = _React$useState4[1];

  return React.createElement("div", {
    className: "footer-nav__section right"
  }, React.createElement("h4", {
    className: "footer-nav__section-header"
  }, React.createElement(FormattedMessage, commonMessages.preferences)), React.createElement("div", {
    className: "footer-nav__section-content"
  }, React.createElement("p", null, React.createElement("button", {
    onClick: function onClick() {
      setDisplayModal(true);
    }
  }, localeFlag[locale] && React.createElement("span", {
    className: "flag-icons dot ".concat(localeFlag[locale])
  }), " ", intl.formatMessage(commonMessages.language), ": ", localeNames[locale])), React.createElement("p", null, React.createElement("button", {
    onClick: function onClick() {
      setDisplayModalShipsTo(true);
    }
  }, localeFlagShipsTo[locale] && React.createElement("span", {
    className: "flag-icons dot ".concat(localeFlagShipsTo[locale])
  }), " ", intl.formatMessage(commonMessages.shippingto), " ")), locale === "it" ? React.createElement("b", {
    className: "footer-nav__section-subheader"
  }, intl.formatMessage(commonMessages.shippingtooptions_1)) : React.createElement("b", {
    className: "footer-nav__section-subheader"
  }, intl.formatMessage(commonMessages.shippingtooptions_2)), React.createElement("div", {
    className: "footer-paymentMethods"
  }, React.createElement("div", {
    className: "footer-paymentMethods__cards"
  }, React.createElement("img", {
    src: "https://api.a-dam.com/storage/icons/visa.svg",
    alt: "visa"
  }), React.createElement("img", {
    src: "https://api.a-dam.com/storage/icons/amex.svg",
    alt: "amex"
  }), React.createElement("img", {
    src: "https://api.a-dam.com/storage/icons/mastercard.svg",
    alt: "mastercard"
  }), React.createElement("img", {
    src: "https://www.mollie.com/external/icons/payment-methods/ideal.svg",
    alt: "ideal"
  }), React.createElement("img", {
    src: "https://www.mollie.com/external/icons/payment-methods/paypal.svg",
    alt: "paypal"
  })))), displayModal && React.createElement(PreferencesFormModal, {
    hideModal: function hideModal() {
      setDisplayModal(false);
    },
    submitBtnText: intl.formatMessage(_defineProperty({
      defaultMessage: "Save preferences",
      "id": "components.Footer.LocaleSelect.3389670008"
    }, "id", "components.Footer.LocaleSelect.3389670008")),
    title: intl.formatMessage(commonMessages.preferences),
    formId: "preferences-form"
  }), displayModalShipsTo && React.createElement(PreferencesFormShippingModal, {
    hideModal: function hideModal() {
      setDisplayModalShipsTo(false);
    },
    submitBtnText: intl.formatMessage(_defineProperty({
      defaultMessage: "Save preferences",
      "id": "components.Footer.LocaleSelect.3389670008"
    }, "id", "components.Footer.LocaleSelect.3389670008")),
    title: intl.formatMessage(commonMessages.preferences),
    formId: "preferences-form"
  }));
};

export default LocaleSelect;