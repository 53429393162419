import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import LoadingBar from "react-top-loading-bar";
import { PersonalizationForm } from "../PersonalizationForm";
import { Modal } from "../Modal";
import * as S from "./styles";
export var PersonalizationFormModal = function PersonalizationFormModal(_ref) {
  var hideModal = _ref.hideModal,
      submitBtnText = _ref.submitBtnText,
      target = _ref.target,
      title = _ref.title,
      userId = _ref.userId,
      formId = _ref.formId,
      props = _objectWithoutProperties(_ref, ["hideModal", "submitBtnText", "target", "title", "userId", "formId"]);

  var _React$useState = React.useState(true),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      show = _React$useState2[0],
      setShow = _React$useState2[1];

  var _React$useState3 = React.useState(),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      status = _React$useState4[0],
      setStatus = _React$useState4[1];

  var _React$useState5 = React.useState(true),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      loading = _React$useState6[0],
      setLoading = _React$useState6[1];

  var _React$useState7 = React.useState(""),
      _React$useState8 = _slicedToArray(_React$useState7, 2),
      msgAfterSubmit = _React$useState8[0],
      setMsgAfterSubmit = _React$useState8[1];

  var _React$useState9 = React.useState(0),
      _React$useState10 = _slicedToArray(_React$useState9, 2),
      progress = _React$useState10[0],
      setProgress = _React$useState10[1];

  var errors = [];
  React.useEffect(function () {
    if (status === 200) {
      setTimeout(function () {
        return hideModal();
      }, 3000);
      setMsgAfterSubmit("Message has been delivered we will be shortly in touch.");
    }

    if (status !== 200) setMsgAfterSubmit("Sorry, the message was not delivered, an error occurred.");
  }, [status]); // @ts-ignore

  var _handleSubmit = function handleSubmit(event) {
    fetch("https://storitalia-v211.herokuapp.com/graphql/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        operationName: "requestCustomization",
        variables: {
          email: event.email,
          redirectUrl: window.location.href,
          name: event.firstName,
          msg: event.description
        },
        query: "fragment AccountErrorFragment on AccountError {\n  code\n  field\n  __typename\n}\n\nmutation requestCustomization($name: String!, $msg: String!, $email: String!, $redirectUrl: String!) {\n  requestCustomization(email: $email, name: $name, redirectUrl: $redirectUrl, msg: $msg) {\n    errors: accountErrors {\n      ...AccountErrorFragment\n      __typename\n    }\n    __typename\n  }\n}\n"
      }),
      redirect: "follow"
    }).then(function (result) {
      setLoading(false);
      setStatus(result.status);
      setProgress(100);
    }).catch(function (error) {
      setLoading(false);
    });
  };

  return React.createElement(Modal, {
    submitButtonTestingContext: "submitPersonalizationFormModalButton",
    testingContext: "submitPersonalizationFormModal",
    title: title,
    hide: function hide() {
      hideModal();
      setShow(false);
      setLoading(false);
    },
    formId: formId,
    disabled: false,
    show: show,
    target: target,
    submitBtnText: submitBtnText
  }, React.createElement(LoadingBar, {
    color: "#0D233F",
    progress: progress
  }), loading ? React.createElement(PersonalizationForm, _extends({}, props, {
    errors: errors
  }, {
    formId: formId,
    handleSubmit: function handleSubmit(e) {
      _handleSubmit(e);
    }
  })) : React.createElement(S.StatusMessage, null, msgAfterSubmit));
};