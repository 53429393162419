import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n        display: none;\n      "]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n        writing-mode: unset;\n      "]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  width: auto;\n  height: auto;\n  max-width: 100%;\n  flex: 50%;\n  > span {\n    display: flex !important;\n    height: 100%;\n    object-fit: cover;\n    img {\n      height: auto;\n      max-width: 100%;\n      border-radius: 0;\n      border-top-right-radius: 5px;\n    }\n  }\n\n  > div {\n    h3 {\n      margin-bottom: 10px;\n      writing-mode: unset;\n      text-orientation: mixed;\n      ", "\n    }\n\n    p {\n      ", "\n    }\n\n    height: auto;\n    max-width: 100%;\n    grid-column-end: span 0;\n    padding: 10px;\n    overflow: hidden;\n    line-height: 2rem;\n    max-height: 8rem;\n    -webkit-box-orient: unset;\n    display: block;\n    display: -webkit-box;\n    overflow: hidden !important;\n    text-overflow: ellipsis;\n    -webkit-line-clamp: 4;\n    position: absolute;\n    color: white;\n  }\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  font-weight: normal;\n  font-family: \"Cardo\", serif;\n  font-style: italic;\n  ", "\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  font-weight: normal;\n  ", "\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  background: #f6f6f6;\n  padding: 0.5rem;\n  text-align: center;\n  transition: 0.3s;\n  :hover {\n    background: #d1dee6;\n    color: black;\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n      font-size: 20px;\n    "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n    font-size: 13px;\n    "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n      font-size: 20px;\n    "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  padding: 10% 0px;\n  background-color: #0d233f;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #fff;\n  border-radius: 0px;\n  border-bottom-right-radius: 5px;\n  border-bottom-left-radius: 5px;\n  h3 {\n    font-size: 25px;\n    text-align: center;\n    word-break: break-word;\n    ", "\n    ", "\n    ", "\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  flex: 50%;\n  background-color: #fff\n  border-radius: 0px;\n  border-top-left-radius: 5px;\n  display: flex;\n  align-items: center;\n  > img {\n    height: auto;\n    max-width: 100%;\n    object-fit: cover;\n    border-radius: 0px;\n    border-top-left-radius: 5px;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    text-align: center;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    text-align: center;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  margin: 0 0 0.5rem 0;\n  text-align: center;\n  ", "\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
import { css } from "styled-components";
var textProps = css(_templateObject(), function (props) {
  return props.theme.typography.baseFontSize;
}, media.mediumScreen(_templateObject2()), media.smallScreen(_templateObject3()));
export var WrapperImg = styled.div(_templateObject4());
export var ImageCollectionItem = styled.div(_templateObject5());
export var CollectionsName = styled.div(_templateObject6(), media.largeScreen(_templateObject7()), media.mediumScreen(_templateObject8()), media.smallScreen(_templateObject9()));
export var Wrapper = styled.div(_templateObject10());
export var Title = styled.h4(_templateObject11(), textProps);
export var ArtisanName = styled.p(_templateObject12(), textProps);
export var Price = styled.p(_templateObject13(), textProps);
export var Image = styled.div(_templateObject14(), media.smallScreen(_templateObject15()), media.smallScreen(_templateObject16()));