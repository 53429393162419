import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import { maybe } from "@utils/misc";
import { PlaceholderImage } from "@components/atoms";
import { CachedImage } from "..";
export var ThumbnailCollection = function ThumbnailCollection(_ref) {
  var source = _ref.source,
      children = _ref.children,
      props = _objectWithoutProperties(_ref, ["source", "children"]);

  var backgroundImage = source.backgroundImage;

  if (!backgroundImage) {
    return React.createElement(PlaceholderImage, null);
  }

  return React.createElement(CachedImage, _extends({}, props, {
    url: maybe(function () {
      return backgroundImage.url;
    }),
    alt: maybe(function () {
      return backgroundImage.alt;
    })
  }), children);
};