import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Button, Loader } from "@components/atoms";
import { ProductTileStories } from "@components/molecules";
import { generateCollectionUrl } from "../../../../core/utils";
import * as S from "./styles";
export var ProductListStories = function ProductListStories(_ref) {
  var products = _ref.products,
      _ref$canLoadMore = _ref.canLoadMore,
      canLoadMore = _ref$canLoadMore === void 0 ? false : _ref$canLoadMore,
      _ref$loading = _ref.loading,
      loading = _ref$loading === void 0 ? false : _ref$loading,
      testingContextId = _ref.testingContextId,
      _ref$onLoadMore = _ref.onLoadMore,
      onLoadMore = _ref$onLoadMore === void 0 ? function () {
    return null;
  } : _ref$onLoadMore;
  return React.createElement(React.Fragment, null, React.createElement(S.List, {
    "data-test": "ProductListStories",
    "data-test-id": testingContextId
  }, products.map(function (product) {
    var _product$collections$ = product.collections[0],
        id = _product$collections$.id,
        name = _product$collections$.name;
    return id && name && React.createElement(Link, {
      to: generateCollectionUrl(id, name),
      key: id
    }, React.createElement(ProductTileStories, {
      product: product
    }));
  })), React.createElement(S.Loader, null, loading ? React.createElement(Loader, null) : canLoadMore && React.createElement(Button, {
    testingContext: "loadMoreProductsButton",
    color: "secondary",
    onClick: onLoadMore
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ProductListStories.ProductListStories.108342258",
    defaultMessage: "Load More"
  }))));
};