import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { useLocalStorage } from "@hooks";
import { Locale } from "@types";
import detectBrowserLanguage from "detect-browser-language";
var browserLang = detectBrowserLanguage() === "it" || detectBrowserLanguage() === "it-it" || detectBrowserLanguage() === "it-IT" ? {
  locale: Locale.IT
} : {
  locale: Locale.EN
};
var browserSetLang = window.localStorage.preferences === "it" ? {
  locale: Locale.IT
} : window.localStorage.preferences === "en" ? {
  locale: Locale.EN
} : {
  locale: Locale.IT
};
export var defaultPreferences = browserSetLang || browserLang;
export var PreferencesContext = React.createContext({
  preferences: defaultPreferences,
  setPreferences: function setPreferences() {
    return window.location.reload(false);
  }
});
var PreferencesConsumer = PreferencesContext.Consumer,
    RawPreferencesProvider = PreferencesContext.Provider;

var PreferencesProvider = function PreferencesProvider(_ref) {
  var defaultLocale = _ref.defaultLocale,
      children = _ref.children;

  if (defaultLocale) {
    defaultPreferences.locale = defaultLocale;
  }

  var _useLocalStorage = useLocalStorage("preferences", defaultPreferences),
      _useLocalStorage2 = _slicedToArray(_useLocalStorage, 2),
      preferences = _useLocalStorage2[0],
      setPreferences = _useLocalStorage2[1];

  return React.createElement(RawPreferencesProvider, {
    value: {
      preferences: preferences,
      setPreferences: setPreferences
    }
  }, children);
};

export { PreferencesProvider, PreferencesConsumer, RawPreferencesProvider };